<template>
  <div class="row full-width q-pa-xl justify-center">
    <div class="row full-width">
      <q-btn flat :to="{name: 'login'}">Back To Login</q-btn>
    </div>
    <q-timeline layout="loose" style="width: 800px">
      <q-timeline-entry heading>LMS Version History</q-timeline-entry>

      <q-timeline-entry v-for="(version, i) in versions" :key="i" :title="version.versionNumber" :subtitle="version.releaseDate" :side="getSide(i)">
        <q-list>
          <q-item v-for="(item, j) in version.releaseNotes" :key="j" dense clickable @click="showDetails(item)">
            <q-item-section avatar style="min-width: 0px" v-if="getSide(i) === 'right'">
              <q-icon name="chevron_right" size="20px"/>
            </q-item-section>
            <q-item-section>{{ item.title }}</q-item-section>
            <q-item-section avatar style="min-width: 0px" v-if="getSide(i) === 'left'">
              <q-icon name="chevron_left" size="20px"/>
            </q-item-section>
          </q-item>
        </q-list>
      </q-timeline-entry>
    </q-timeline>

    <q-dialog v-model="showDetailModal" @hide="detailedItem = {}">
      <q-card class="q-pa-md" style="min-width: 568px">
        <div class="row justify-end">
          <q-btn dense flat icon="close" style="z-index: 2" @click="showDetailModal = false">
            <q-tooltip>Close</q-tooltip>
          </q-btn>
        </div>
        <div>
          <div class="text-center text-h5">{{ detailedItem.title }}</div>
        </div>
        <div class="text-center q-my-lg">{{ detailedItem.description || 'No Additional Information Available' }}</div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import versionHistory from '../statics/versionHistory.json'
export default {
  name: 'VersionHistory',
  data () {
    return {
      versions: [],
      showDetailModal: false,
      detailedItem: {}
    }
  },
  methods: {
    getSide (index) {
      return index % 2 === 0 ? 'right' : 'left'
    },
    showDetails (item) {
      this.detailedItem = item
      this.showDetailModal = true
    }
  },
  async created () {
    this.versions = versionHistory
  }
}
</script>
